import React from "react"
import { useStaticQuery, StaticQuery, graphql } from "gatsby"
import { RiArrowRightSLine } from "react-icons/ri"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import Img from "gatsby-image"

const PostMaker = ({ data1, data2, data3 }) => {
  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }
  return (
    <section className="home-posts">
      <Container>
        <Row className="pt-5">
          <Col>
            <h2 className="font-weight-bold text-center">
              {tt("LatestPosts")}
            </h2>
          </Col>
        </Row>
      </Container>
      <Container fluid="true">
        <Row>
          {data1}
          {data2}
          {data3}
        </Row>
      </Container>
    </section>
  )
}

const BlogListHome = () => {
  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }
  const data = useStaticQuery(graphql`
    query {
      p_nsp: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { eq: "NSP個案" } } }
        limit: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              tags
              pathPrefix
            }
          }
        }
      }
      p_mainland: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { eq: "國內個案" } } }
        limit: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              tags
              pathPrefix
            }
          }
        }
      }
      p_adult: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { eq: "成人個案" } } }
        limit: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              tags
              pathPrefix
            }
          }
        }
      }
      p_nsp_cn: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { eq: "NSP个案" } } }
        limit: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              tags
              pathPrefix
            }
          }
        }
      }
      p_mainland_cn: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { eq: "国內个案" } } }
        limit: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              tags
              pathPrefix
            }
          }
        }
      }
      p_adult_cn: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { eq: "成人个案" } } }
        limit: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              tags
              pathPrefix
            }
          }
        }
      }
      p_nsp_en: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { eq: "NSP Case" } } }
        limit: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              tags
              pathPrefix
            }
          }
        }
      }
      p_mainland_en: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { eq: "Mainland Case" } } }
        limit: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              tags
              pathPrefix
            }
          }
        }
      }
      p_adult_en: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { eq: "Adult Case" } } }
        limit: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              tags
              pathPrefix
            }
          }
        }
      }
      s1: file(relativePath: { eq: "case_icon_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      s2: file(relativePath: { eq: "case_icon_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      s3: file(relativePath: { eq: "case_icon_3.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const p_nsp =
    locale === "zh-hk"
      ? data.p_nsp.edges.filter(edge => !!edge.node.frontmatter.date)
      : ( locale === "zh-cn"
	  ? data.p_nsp_cn.edges.filter(edge => !!edge.node.frontmatter.date)
	  : data.p_nsp_en.edges.filter(edge => !!edge.node.frontmatter.date))
  const p_mainland =
    locale === "zh-hk"
      ? data.p_mainland.edges.filter(edge => !!edge.node.frontmatter.date)
      : ( locale === "zh-cn"
	  ? data.p_mainland_cn.edges.filter(edge => !!edge.node.frontmatter.date)
	  : data.p_mainland_en.edges.filter(edge => !!edge.node.frontmatter.date))
  const p_adult =
    locale === "zh-hk"
      ? data.p_adult.edges.filter(edge => !!edge.node.frontmatter.date)
      : ( locale === "zh-cn"
	  ? data.p_adult_cn.edges.filter(edge => !!edge.node.frontmatter.date)
	  : data.p_adult_en.edges.filter(edge => !!edge.node.frontmatter.date))

  const p1 = p_adult[0].node
  const p2 = p_mainland[0].node
  const p3 = p_nsp[0].node

  const post1 = (
    <Col xl={4} lg={4} md={4} sm={4} xs={12} className="post-item">
      <Img fluid={data.s1.childImageSharp.fluid} />
      <div>
        <Link to={p1.frontmatter.pathPrefix + p1.frontmatter.slug}>
          <p className="text-left">{tt("adultCases")}｜</p>
          <p className="text-right">{p1.frontmatter.title}</p>
        </Link>
      </div>
    </Col>
  )
  const post2 = (
    <Col xl={4} lg={4} md={4} sm={4} xs={12} className="post-item">
      <Img fluid={data.s2.childImageSharp.fluid} />
      <div>
        <Link to={p2.frontmatter.pathPrefix + p2.frontmatter.slug}>
          <p className="text-left">{tt("mainlandCases")}｜</p>
          <p className="text-right">{p2.frontmatter.title}</p>
        </Link>
      </div>
    </Col>
  )
  const post3 = (
    <Col xl={4} lg={4} md={4} sm={4} xs={12} className="post-item">
      <Img fluid={data.s3.childImageSharp.fluid} />
      <div>
        <Link to={p3.frontmatter.pathPrefix + p3.frontmatter.slug}>
          <p className="text-left">{tt("nspCases")}｜</p>
          <p className="text-right">{p3.frontmatter.title}</p>
        </Link>
      </div>
    </Col>
  )

  return <PostMaker data1={post1} data2={post2} data3={post3} />
}

export default BlogListHome

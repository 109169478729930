import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-intl"

import MapIconImg from "../../static/assets/map_icon.png"
import PhoneIconImg from "../../static/assets/phone_icon.png"
import FacebookIconImg from "../../static/assets/fb_icon.png"

const TitleBarCss = css`
  background-color: var(--title-bg-color);
  color: var(--bg-color);
  margin: 0;
  padding: 0;
`

const styles = {
  c0: {
    margin: 0,
    padding: 0,
  },
  c1: {
    height: "100%",
  },
  c2: {
    minWidth: "50px",
    maxWidth: "50px",
  },
  c3: {
    color: "#166FE5",
  },
  c4: {
    maxWidth: "100px",
  },
  r0: {
    width: "100%",
    margin: 0,
    padding: 0,
    marginBottom: "10px",
  },
  r1: {
    height: "100%",
    margin: 0,
    padding: 0,
  },
  p0: {
    color: "#3b5998",
    marginBottom: "0px",
  },
}

const ContactUs = ({ t, data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const intl = useIntl()
  const locale = intl.locale
  return (
    <Container id="contact" className="contactus-container">
      <Row className="pt-5 pb-2">
        <Col>
          <h2 className="font-weight-bold text-center">{t("ContactUs")}</h2>
        </Col>
      </Row>
      <Row className="contact">
        <Col sm={8}>
          <div
            className="pl-2 py-2 d-flex flex-column font-weight-bold"
            css={TitleBarCss}
          >
            {t("NpClinic")}
          </div>
          <Row style={styles.r0}>
            <Col style={styles.c0}>
              <Row className="mt-2 ml-1">
                <Col style={styles.c2}>
                  <img style={{ width: "100%" }} src={MapIconImg} alt="" />
                </Col>
                <Col>
                  {locale === "zh-hk" && (
                    <p className="mb-0">{frontmatter.address1.addrhk}</p>
                  )}
                  {locale === "zh-cn" && (
                    <p className="mb-0">{frontmatter.address1.addrcn}</p>
                  )}
                  <p className="mb-0">{frontmatter.address1.addren}</p>
                </Col>
              </Row>
              <Row className="mt-2 ml-1">
                <Col style={styles.c2}>
                  <img style={{ width: "100%" }} src={PhoneIconImg} alt="" />
                </Col>
                <Col>{frontmatter.address1.tel}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <Row style={styles.r1}>
            <iframe
              style={{ width: "100%", height: "100%" }}
              src="https://maps.google.com/maps?q=338%20King's%20Road,%20North%20Point,%20Hong%20Kong&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </Row>
        </Col>
      </Row>

      <Row style={styles.r0}>
        <Col sm={8}>
          <div
            className="pl-2 py-2 d-flex flex-column font-weight-bold"
            css={TitleBarCss}
          >
            {t("OfficeHours")}
          </div>
          <Row style={styles.r0}>
            <Col style={styles.c0}>
              <div className="mt-2 ml-2">
                {locale !== "en" && (
                  <p className="mb-0">{frontmatter.officeHours.zh1}</p>
                )}
                {locale !== "en" && (
                  <p className="mb-0">{frontmatter.officeHours.zh2}</p>
                )}
                {locale !== "en" && (
                  <p className="mb-0">{frontmatter.officeHours.zh3}</p>
                )}
                {locale === "en" && (
                  <p className="mb-0">{frontmatter.officeHours.en1}</p>
                )}
                {locale === "en" && (
                  <p className="mb-0">{frontmatter.officeHours.en2}</p>
                )}
                {locale === "en" && (
                  <p className="mb-0">{frontmatter.officeHours.en3}</p>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <Row
            style={{ height: "100%" }}
            className="d-flex text-center justify-content-center align-items-center"
          >
            <Col xs={8} style={styles.c3}>
              <a
                style={styles.p0}
                href="https://www.facebook.com/JoanneHaSpeech"
              >
                Follow us on Facebook:
                <br />
                @JoanneHaSpeech
              </a>
            </Col>
            <Col xs={4} style={styles.c4}>
              <a
                style={styles.p0}
                href="https://www.facebook.com/JoanneHaSpeech"
              >
                <img style={{ width: "100%" }} src={FacebookIconImg} alt="" />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactUs
